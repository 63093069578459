
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
mat-dialog-container {

  position: relative;

}

.labs {
  .mat-expansion-panel-body {
    padding: 0 0 4px;
  }
}


md-tabs [role="tabpanel"] {
  transition: none;
}

.theme-default {

  .mat-mdc-input-element {
    font-size: 14px;
    line-height: 1.3em;
  }

}

.mat-mdc-dialog-content {

  .mat-horizontal-stepper-header-container {
    position: absolute;
    width: 100%;
    background: #FFF;
    z-index: 100;
  }

  .mat-horizontal-content-container {
    margin-top: 72px;
  }

}

.mat-vertical-stepper-header {
  padding: 12px 24px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-infix {
  padding: inherit;
}





.ng-dropdown-panel {

  &.users {

    .ng-dropdown-panel-items {

      .ng-option {
        line-height: 1.5em;
        padding: 4px 8px;
      }

    }

  }

  .ng-dropdown-panel-items {

    .ng-option {

      &.ng-option-selected {
        background: #ACCBD4;
        color: #ffffff;
      }
    }

  }

}

.users {
  .ng-value-container {

    .profile {

      padding: 4px 8px;
      background: #efebeb;
      border-radius: 15px;

      img {
        margin-right: 4px;
      }

    }

  }
}

// Steppers in (dialog)

.mat-mdc-dialog-container {

  .mat-vertical-stepper-header {
    padding: 8px 24px;
  }

  .action-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #d7d9dc;
    z-index: 100;
    padding: 8px;

    .cancel-button {
      left: 10px;
    }

  }


}

//.mat-step-icon .mat-icon {
//  margin: 4px;
//}

.mat-expansion-panel {

  //.mat-header-cell {
  //  color: black !important;
  //  font-weight: bold;
  //  font-size: 20px;
  //}

  .mat-mdc-row,
  .mat-mdc-header-row {
    min-height: 34px;
  }

}

.mat-expansion-panel-body {
  padding: 12px 0 12px;

}

.mat-grid-tile .mat-figure {
  align-items: unset !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout .mat-checkbox-label {
  font-size: 13px;
}


/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
mat-card {
  padding: 8px !important;
  //margin: 0 10px 20px 0;
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  .mat-card-header-text {
    margin: 0 8px 0 0;
  }

  .profile img {
    margin-left: 8px;
  }

  .mat-mdc-card-title {
    font-size: 20px !important;
    padding-top: 8px;
    padding-bottom: 2px;
    margin-bottom: 0;
  }

  .mat-mdc-card-subtitle{
    margin-bottom: 0
  }

}

.inline-badge {
  background: #3d8c59;
  color: #c7f5d3;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
}

.org-unit {
  border-bottom: 1px dotted #fff;
  padding: 1px 0;

  &:last-of-type {
    border: none;
  }
}





.message-box {
  padding: 8px;
}


// Audit datetime picker
.auditLog {
  .md-drppicker {
    display: flex;
  }
  .md-drppicker.double {
    left: 0 !important;
    right: -430px !important;

  }
}

// Timepicker overrides

.accent .timeframe-input {
  background: #fbf4f4;
  padding: 4px;
  max-width: 180px;
  color: #3b3b3b !important;
  font-size: 13px;
  border-radius: 3px;
}
.md-drppicker td.available, .md-drppicker td.available.end-date, .md-drppicker td.available.in-range, .md-drppicker td.available.start-date{
  color : black !important;
}

.md-drppicker.double {
  width: auto;
  left: auto !important;
  right: 116px !important;
}

.md-drppicker {

  .ranges {
    width: 220px !important;
    max-height: 308px;
    overflow-y: scroll;
    background: #d6e7f5;
    border-radius: 3px;

    li {

      :hover {
        background-color: #637687;
      }

      button {
        color: #463f3f;
      }
    }
  }
}

::ng-deep {
  .md-drppicker td, .md-drppicker th {
    height: 24px;
  }

  .md-drppicker .calendar td, .md-drppicker .calendar th {
    min-width: 24px;
  }
}

.wee-icon {
  .mat-icon {
    height: 18px;
    width: 18px;
  }
}


.ng-select {
  padding-bottom: 0;
}


.mat-mdc-form-field.stretch-height,
.mat-form-field.stretch-height .mat-form-field-flex,
.mat-form-field.stretch-height textarea {
  height: 100%;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.stretch-height .mat-form-field-wrapper {
  height: calc(100% - 1.34375em);
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.stretch-height .mat-form-field-infix {
  height: calc(100% - 1.34375em - 2px);
}

.mat-mdc-form-field {
  &.color-picker-input {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      display: none;
    }
  }
}


.export-item {

  .mat-expansion-panel-body {
    padding: 0 12px 16px;
  }

}


.search-container {
  .ng-select {
    .ng-spinner-loader {
      bottom: -10px !important;
    }
  }
}

.mat-mdc-menu-content:not(:empty) {
  padding: 0;
}

.cdk-overlay-pane {
  transform: inherit !important; // Need to add this to fix the bulk actions menu positioning
}

.multiline {
  text-align: left !important;
  white-space: pre-line !important; /* Ensures the tooltip interprets line breaks */

  > * {
    text-align: left !important;
  }
}