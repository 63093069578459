.mat-mdc-button-base {
  font-weight: bold;
}
button > mat-icon {
  font-size: 24px;
}

.mat-mdc-unelevated-button > .mat-icon {
  font-size: 24px !important;
  color: white;
}

.mat-mdc-button > .mat-icon {
  font-size: 24px !important;
}

.mat-mdc-raised-button > .mat-icon {
  font-size: 24px !important;
}

body.theme-default .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-label-text-color: #fff;
}
body.theme-default .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-label-text-color: #fff;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  --mdc-dialog-subhead-color: white;
}

.mat-mdc-checkbox
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  --mdc-checkbox-selected-checkmark-color: #fff;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-color: black;
}

// prevents checkbox from cutting off in ag grid header
.ag-header-cell-comp-wrapper {
  overflow: unset;
}

// LSC-1992 - this is a hack that has the potential to cause problems
cdw-ag-grid-select-all-column-header .mat-mdc-checkbox {
  margin-left: -12px !important;
}

.mat-mdc-card-title {
  --mdc-typography-headline6-line-height: 1.4;
  --mdc-typography-headline6-font-weight: bold;
}

// LSC-1992 - this is a hack
.error-negative-margin {
  margin-top: -20px;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  overflow-y: unset !important;
}

.flex-important {
  display: flex !important; 
}