
//
///**
// * Calculate the luminance for a color.
// * See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
// */
//@function luminance($color) {
//  $red: nth($linear-channel-values, red($color) + 1);
//  $green: nth($linear-channel-values, green($color) + 1);
//  $blue: nth($linear-channel-values, blue($color) + 1);
//
//  @return .2126 * $red + .7152 * $green + .0722 * $blue;
//}
//
///**
// * Calculate the contrast ratio between two colors.
// * See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
// */
//@function contrast($back, $front) {
//  $backLum: luminance($back) + .05;
//  $foreLum: luminance($front) + .05;
//
//  @return max($backLum, $foreLum) / min($backLum, $foreLum);
//}
//
///**
// * Determine whether to use dark or light text on top of given color.
// * Returns black for dark text and white for light text.
// */
//@function choose-contrast-color($color) {
//  $lightContrast: contrast($color, white);
//  $darkContrast: contrast($color, black);
//
//  @if ($lightContrast > $darkContrast) {
//    @return white;
//  }
//  @else {
//    @return black;
//  }
//}

@import './styles/mixins';

@mixin generate-grid-color-classes($colorName, $color, $contrastColor, $hue) {

  //.#{$colorName}#{$hue} {
  //  background-color: $color !important;
  //  color: $contrastColor !important;
  //}

  .k-grid {

    tr.#{''+$colorName+''}#{$hue} {
      background-color: $color !important;
      color: $contrastColor !important;

      &:hover {
        background-color: lighten($color, 5) !important;
      }
    }


  }

  .ag-root {

    .ag-row {
      border: #b4b7b9;
    }

    .ag-row.#{''+$colorName+''}#{$hue} {
      background-color: $color !important;
      color: $contrastColor !important;

      &:hover {
        background-color: lighten($color, 5) !important;
      }
    }

    .ag-row.ag-row-selected {
      background-color: #b7e4ff !important;
      color: #333 !important;

      &:hover {
        background-color: lighten(#b7e4ff, 5) !important;
      }
    }

  }

  .profile {

    &.#{''+$colorName+''}#{$hue} {
      background-color: $color !important;
      color: $contrastColor !important;

      &:hover {
        background-color: lighten($color, 5) !important;
      }
    }
  }

}

@mixin grid-color-classes($palettes) {

  // Generate the color classes...
  @each $paletteName, $palette in $palettes {

    // Get the contrasts map
    $contrasts: map-get($palette, 'contrast');

    @each $hue in $matHues {

      // Get the color and the contrasting color
      $color: map-get($palette, $hue);
      $contrast: map-get($contrasts, $hue);

      @if ($color != null and $contrast != null) {

        // Generate color classes
        @include generate-grid-color-classes($paletteName, $color, $contrast, '-#{$hue}');

      }
    }
  }

}


$active: (
        50: #d8ebd4,
        100: #d8ebd4,
        contrast: (
                50: $dark-primary-text,
                100: $dark-primary-text
        )
);

$archived: (
        50: #847f80,
        100: #847f80,
        contrast: (
                50: #eeeae8,
                100: #eeeae8
        )
);

$provisioned: (
        50: #ebebeb,
        100: #ebebeb,
        contrast: (
                50: $dark-primary-text,
                100: $dark-primary-text
        )
);

$declined: (
        50: #e94d28,
        100: #e94d28,
        contrast: (
                50: $light-primary-text,
                100: $light-primary-text
        )
);

$not_found: (
        50: #72787a,
        100: #72787a,
        contrast: (
                50: $light-primary-text,
                100: $light-primary-text
        )
);

$custom_palettes: (
        archived: $archived,
        provisioned: $provisioned,
        declined: $declined,
        active: $active,
        not_found: $not_found
);

.k-grid {

  tr {

    th:first-child {
      padding: 4px 4px;
    }

    &[class^='archived'],
    &[class*=' archived'] {
      font-style: italic;
    }

  }

  td {
    font-size: 12px;
    padding: 4px 4px;
    border-bottom: 1px solid #DDD;
    font-family: Helvetica Neue, Helvetica, Arial;

    .material-icons {
      font-size: 16px;
      line-height: 24px;
    }

  }

  td.k-state-selected,
  tr.k-state-selected {
    font-weight: bold;
  }

  td.text-center {
    text-align: center;
  }
}

@include grid-color-classes($custom_palettes);

.unknown-count {
  color: #a03030;
  position: relative;

  .material-icons {
    font-size: 18px;
    height: 18px;
    width: 18px;
    min-height: 18px;
    vertical-align: middle;
  }

}


// Header

.header {

  .hdr3 {
    margin: 14px 6px;
    font-size: 16px;
  }

  .material-icons {
    color: currentColor;
    vertical-align: middle;
    font-size: 22px;
    width: 22px;
    height: 22px;
  }

}


.page-layout.simple.fullwidth > .header,
.page-layout.simple.inner-sidebar > .header {

  &.p-nudge {
    height: 48px;
    min-height: 48px;
    max-height: 48px;
  }

}

.p-nudge {
  padding: 0 12px;
}

.mat-mdc-menu-panel {
  max-width: 360px !important;
}

.mat-toolbar-footer-row {
  height: 48px !important;
  font-size: 14px;
}





.label-field {

  margin:6px 0;

  .label {
    font-weight: 500;
  }

  .value {
    font-style: italic;

    .md-button {
      font-style:normal;
    }

  }
}

.clickable:hover {
  cursor: pointer;
}

@import './styles/tags';
@import './styles/students';
@import './styles/panels';
@import './styles/mouse';
@import './styles/forms';
@import './styles/md-overrides';
@import './styles/globals';

@import './styles/ag-grid-components';

.spinner-overlay {
  position: absolute;
  top:130px;
  background: #efefef;
  opacity: 0.6;
  width: 100%;
  height: calc(100% - 130px - 50px);

  &.actions {
    top: 60px;
    height: calc(100% - 60px - 50px);
  }
}

.widget {
  position: relative;

  .mini-spinner-overlay {
    position: absolute;
    top: 10px;
    background: #efefef;
    opacity: 0.6;
    width: 100%;
    height: 100%;
  }

}

.full-dialog-content-height {
  height: calc(100% - 60px - 50px);
}

html, body {
  overflow-y: scroll;
}


//Column Chooser

.column-chooser {

  &.mat-mdc-menu-panel {

    max-height: 320px;

    .mat-mdc-menu-item {
      font-size: 12px;
      line-height: 32px;
      height: 32px;

      .em {
        font-style: italic;
      }

      .material-icons {
        font-size: 20px;
        min-height: 20px;
        height: 20px;
      }

    }

  }

}


body.theme-default .mat-mdc-unelevated-button {
  &.confirm {
    background-color: #58b938;
    color: #FFF;
  }
}


.background-task-status {
  margin: 0;
  background-color: #e9f9ff;
  color: #2f538c;
}


.page-layout.simple.fullwidth > .header.p-nudge, .page-layout.simple.inner-sidebar > .header.p-nudge {
  height: 54px;
  min-height: 54px;
  max-height: 54px;
}

//AG Grid styles

.icon-header {

  .ag-icon {
    position: absolute;
    right: 0;
    top: 4px;
  }
}

.ag-row .ag-cell {
  display: flex;
  //justify-content: center; /* align horizontal */
  align-items: center;

  &.text-center {
    display: inline-grid !important;
  }

}

.ag-header-row {
  .ag-header-cell {
    &.text-center {
      .ag-header-cell-label {
        justify-content: center;
      }
    }
  }
}


.ait-ag-side-panel {
  font-size: 12px;
  width: 199px;

  .tool-panel-blurb {
    padding: 6px;
  }

  mat-icon {
    font-size:16px;
    height: 16px;
    min-height: 16px;
    width: 16px;
    min-width: 16px;
  }

  .clr-gold {
    color: goldenrod;
  }

  .clr-delete {
    color: darkred;
  }

  .column-view {
    padding:4px;

    &.active {

      background: #e6e2e2;
      font-weight: bold;

      //span {
      //  padding-left: 8px;
      //}
    }
  }


  .header-panel {
    border-bottom: 1px solid #BDC3C7;
    height: 32px;
    padding: 4px;
  }

}

.action-grid {
  width: 100%;
  height: calc(100vh - 240px);
}

.ag-theme-balham {
  .ag-header-cell {
    &.draft {
      color: #ddd;
    }

    &.published {
      color: #0a9138;
    }
  }
}


.multi_lines_text {
  white-space: pre-line;
}

.text-shaded {
  background: #fdf9f9;
  padding: 8px;
}

.simple-snack-bar .mat-mdc-simple-snack-bar {
  justify-content: center;
}

.ag-header-group-cell-label {
  justify-content: center;
}

ngx-material-timepicker-container {
  z-index: 1000;
}

.ngx-material-timepicker-toggle {

  padding: 0 4px !important;

  svg {
    width: 18px;
    height: 18px;
    fill: rgba(0, 0, 0, 0.64);
    top: 4px;
    position: relative;
  }
}
