// -----------------------------------------------------------------------------------------------------
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
        500: white,
        contrast: (
                500: $dark-primary-text
        )
);

$fuse-black: (
        500: black,
        contrast: (
                500: $light-primary-text,
        )
);

$fuse-navy: (
        50: #ECECEE,
        100: #C5C6CB,
        200: #9EA1A9,
        300: #7D818C,
        400: #5C616F,
        500: #3C4252,
        600: #353A48,
        700: #2D323E,
        800: #262933,
        900: #1E2129,
        A100: #C5C6CB,
        A200: #9EA1A9,
        A400: #5C616F,
        A700: #2D323E,
        contrast: (
                50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $light-primary-text,
                400: $light-primary-text,
                500: $light-primary-text,
                600: $light-primary-text,
                700: $light-primary-text,
                800: $light-primary-text,
                900: $light-primary-text,
                A100: $dark-primary-text,
                A200: $light-primary-text,
                A400: $light-primary-text,
                A700: $light-primary-text,
        )
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
        fuse-white: $fuse-white,
        fuse-black: $fuse-black,
        fuse-navy: $fuse-navy
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Force the input field font sizes to 16px
$typography: mat-typography-config(
        $input: mat-typography-level(16px, 1.125, 400)
);

// Setup the typography
@include angular-material-typography($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them

@import "src/app/layout/components/toolbar/toolbar.theme";


// Define a mixin for easier access
@mixin components-theme($theme) {

  // Layout components
  @include toolbar-theme($theme);

}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat-palette($fuse-navy);
$default-accent-palette: mat-palette($mat-light-blue, 600, 400, 700);
$default-warn-palette: mat-palette($mat-red);

// Create the Material theme object
$theme: mat-light-theme($default-primary-palette, $default-accent-palette, $default-warn-palette);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {

  // Create an Angular Material theme from the $theme map
  @include angular-material-theme($theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($theme);

  // Apply the theme to the user components
  @include components-theme($theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
          primary: $default-primary-palette,
          accent: $default-accent-palette,
          warn: $default-warn-palette
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat-palette($mat-yellow, 600, 400, 700);
$yellow-light-theme-warn-palette: mat-palette($mat-red);

// Create the Material theme object
$yellow-light-theme: mat-light-theme($yellow-light-theme-primary-palette, $yellow-light-theme-accent-palette, $yellow-light-theme-warn-palette);

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {

  // Generate the Angular Material theme
  @include angular-material-theme($yellow-light-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($yellow-light-theme);

  // Apply the theme to the user components
  @include components-theme($yellow-light-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
          primary: $yellow-light-theme-primary-palette,
          accent: $yellow-light-theme-accent-palette,
          warn: $yellow-light-theme-warn-palette
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat-palette($mat-blue);
$blue-gray-dark-theme-accent-palette: mat-palette($mat-blue-gray);
$blue-gray-dark-theme-warn-palette: mat-palette($mat-red);

// Create the Material theme object
$blue-gray-dark-theme: mat-dark-theme($blue-gray-dark-theme-primary-palette, $blue-gray-dark-theme-accent-palette, $blue-gray-dark-theme-warn-palette);

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {

  // Generate the Angular Material theme
  @include angular-material-theme($blue-gray-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($blue-gray-dark-theme);

  // Apply the theme to the user components
  @include components-theme($blue-gray-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
          primary: $blue-gray-dark-theme-primary-palette,
          accent: $blue-gray-dark-theme-accent-palette,
          warn: $blue-gray-dark-theme-warn-palette
  );

  @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat-palette($mat-pink);
$pink-dark-theme-accent-palette: mat-palette($mat-pink);
$pink-dark-theme-warn-palette: mat-palette($mat-red);

// Create the Material theme object
$pink-dark-theme: mat-dark-theme($pink-dark-theme-primary-palette, $pink-dark-theme-accent-palette, $pink-dark-theme-warn-palette);

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {

  // Generate the Angular Material theme
  @include angular-material-theme($pink-dark-theme);

  // Apply the theme to the Fuse Core
  @include fuse-core-theme($pink-dark-theme);

  // Apply the theme to the user components
  @include components-theme($pink-dark-theme);

  // Generate Fuse color classes for primary, accent and warn colors
  $palettes: (
          primary: $pink-dark-theme-primary-palette,
          accent: $pink-dark-theme-accent-palette,
          warn: $pink-dark-theme-warn-palette
  );

  @include fuse-color-classes($palettes);
}


// school color picker css

mcc-color-picker-collection {
  margin-top: 5px !important;
}
.mcc-color-picker-selector-preview {
  padding-bottom: 53px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.timeframe-input .mat-select-value {
  color: #000 !important;
}

