@use '../node_modules/@cdw-ae/components-export-status-card/tailwind-classes.scss';

// @tailwind base;
@tailwind components;
@tailwind utilities;

// Import Fuse core library
@import '@amplabs/scss/core';

// Import app.theme.scss
@import 'app/app.theme';

@import 'app/app.core';

@import '@ng-select/ng-select/themes/material.theme.css';

@import '@ag-grid-community/core/dist/styles/ag-grid.css';
@import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';

@import 'app/styles/material-mdc-migration.scss';

// HACK: Ideally we shouldn't need component styles here but I couldn't find a way to position the multi select panel on the heatmap from the component CSS file, possibly due to rendering?
.heat-map-multi-select-panel {
  margin-top: 25px;
}
