// Tags
.tags {

  margin-top: 0px;

  .tag-color-block {
    width: 24px;
    height: 24px;
    margin-right: 2px;
    border-radius: 2px;
  }

  .tag {
    background-color: rgba(0, 0, 0, 0.08);
    font-size: 11px;
    border-radius: 2px;
    padding: 3px 8px;

    .tag-color {
      width: 8px;
      min-width: 8px;
      height: 8px;
      min-height: 8px;
      margin-right: 8px;
      border-radius: 50%;
    }
  }
}

.ag-cell {
  .tags {

    .tag {
      padding: 0 8px;
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: inherit;
  //border-radius: 2px;
  color: inherit;
  padding: 0;
  margin: 4px 4px 4px 0;

  .ng-value-icon {
    color: #cd1210;
    padding-left: 5px;
    font-size: 16px;
  }

}

.ng-select .ng-select-container {

  min-height: 26px;

  .ng-value-container {
    //border-top: 4px; // TODO: Review impact of this removal...
  }

}


.ng-value {

  .tags {
    //margin-top: 12px;
  }

}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
  height: 100%
}
