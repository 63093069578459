$lime: #b0eb00;
$bright-red: #b30015;
$dark-blue: #2a00b3;
$deep-cerulean: #0077b3;
$header-blue: #469BE5;
$bondi-blue: #00b0b3;
$cyan: #24fbff;
$heliotrope: #8a7dff;
$silver-chalice: #a6a6a6;
$subheader-grey: #dcdcdc;
$tarnished-silver: #707070;
$scorpion: #595959;
$tundora: #444;
$mine-shaft: #363636;
$cod-gray: #111;
$forest-green: #2f7e2c;
$grass-green: #4d9e44;
$dark-red: #8d171a;
$tangerine: #e68b16;
$ragRed: #e94d28;
$ragAmber: #efcd45;
$ragGreen: #289b48;
$ragPurple: #9901ff;
$ragLightGreen: #b5d7a9;
$warnAmber: #FF9902;
$warn-amber: #D17D00;

$alertAmber: #ffe599;
$alertRed: #cf2a27;
$buttonGrey: #c5c5c5;
$clr-link-blue: #0072fd;


$burnt-orange: #b05b1d;
$soft-blue: #ddf3ff;