cdw-ag-grid-boolean-check-cell-renderer {
  width: 100%;
}

.ag-theme-balham {
  .ag-input-field-input {
    border: none !important;
    color: #222 !important;
  }
}

.ag-cell-wrapper {
  overflow: hidden;
  width: 100%;
}

.ag-body-viewport {

  .ag-center-cols-viewport {

    .ag-row {

      &.loading {

        &:after {
          content: '';
          //right: 0; bottom: 0; left: 0;
          //top: 2px;
          bottom: 0;
          width: 100%;
          height: 3px;
          z-index: -1;
          position: absolute;
          display: block;
          background: linear-gradient(90deg, #6e9aaf, unquote('#6e9aaf99'), unquote('#6e9aaf33'), #6e9aaf);
          background-size: 2000px 100%;
          -webkit-animation: Gradient 4s ease infinite;
          -moz-animation: Gradient 4s ease infinite;
          animation: Gradient 4s ease infinite;
        }

      }

      &.data-refresh {

        &:after {
          content: '';
          //right: 0; bottom: 0; left: 0;
          //top: 2px;
          bottom: 0;
          width: 100%;
          height: 3px;
          z-index: -1;
          position: absolute;
          display: block;
          background: linear-gradient(90deg, #6e9aaf, unquote('#6e9aaf99'), unquote('#6e9aaf33'), #6e9aaf);
          background-size: 2000px 100%;
          -webkit-animation: Gradient 4s ease infinite;
          -moz-animation: Gradient 4s ease infinite;
          animation: Gradient 4s ease infinite;
        }

      }

    }

  }

  .ag-pinned-left-cols-container {

    .ag-row {

      &.spinner:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        right: 6px;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        border-radius: 50%;
        border: 2px solid #ccc;
        border-top-color: #000;
        animation: spinner .6s linear infinite;
      }

      &.data-refresh {

        //&:before {
        //  content: 'Refreshing...';
        //  position: absolute;
        //  padding: 4px 12px;
        //  background: #d1dee5;
        //  margin: 2px 2px;
        //  right: 2px;
        //  border-radius: 14px;
        //  z-index: 100;
        //  color: #7a9292;
        //  opacity: 0.9;
        //}

        &:after {
          content: '';
          //right: 0; bottom: 0; left: 0;
          //top: 2px;
          bottom: 0;
          width: 100%;
          height: 3px;
          z-index: -1;
          position: absolute;
          display: block;
          background: linear-gradient(90deg, #6e9aaf, #6e9aaf99, #6e9aaf33, #6e9aaf);
          background-size: 2000px 100%;
          -webkit-animation: Gradient 8s ease infinite;
          -moz-animation: Gradient 8s ease infinite;
          animation: Gradient 8s ease infinite;
        }

      }


    }
  }

}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  100% {
    background-position: 100% 50%
  }
  //100% {
  //  background-position: 0% 50%
  //}
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  100% {
    background-position: 100% 50%
  }
  //100% {
  //  background-position: 0% 50%
  //}
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

/* enable basic browser text selection in grid cells */
//div.ag-root .ag-cell-focus {
//  -webkit-user-select: text;
//  -moz-user-select: text;
//  -ms-user-select: text;
//  user-select: text;
//}


.ag-header-cell {
  app-loading-overlay {
    width: 100%;
  }
  cdw-ag-grid-icon-header {
    width: 100%;
  }
}


.ag-grid-container {
  height: 100%;
  position: relative;

  &.max-height {
    height: calc(100% - 20px) !important;
  }

  &.max-height-full {
    height: 100% !important;
  }

  ag-grid-angular {
    width: 100%;
    height: 100%;
  }
}

