.pill-marker {
  background: #ddeaec;
  padding:4px 8px;
  border-radius: 10px;
}

.spring {
  display: flex;
  flex-grow: 1;
}

.selection-info-container {

  padding: 0 16px;
  background: #fff;
  min-height: 40px;

  &.active {
    background: #f5f5ff;
  }

  .mat-icon {
    font-size: 18px;
  }

  .selection-info {
    font-weight: 500;
  }

}

.field-container {
  position: relative;

  .field-label {
    position: absolute;
    top: 6px;

  }
}

.ait-block {
  display: block;
}

// Background tasks

.progress-percentage {
  margin: 0 8px;
  text-align: center;
}

.ait-scroll {
  overflow-y: scroll;
}


// Quill

.user-template {
  vertical-align:top;
  background: #efefef;
  padding: 0 12px;
}

hr {
  margin: 20px 0px;
}


// Job Badge

.job-badge {

  .mat-mdc-progress-spinner circle {
    stroke-width: 2px !important;
    stroke: #e8efe3 !important;
  }

}

// aGrid

.ag-header-cell-filtered {
  background: rgba(243, 237, 168, 0.5);
}

.ag-header-cell.ag-header-cell-sortable {
  .ag-header-cell-sorted-asc,
  .ag-header-cell-sorted-desc,
  .ag-header-cell-sorted-asc [class*="ag-sort-"],
  .ag-header-cell-sorted-desc [class*="ag-sort-"] {
    color: #DDAA1C;
  }
}

.ag-root .ag-row {
  border-top: 1px solid #d2d2d2;
}

// Columns



// Hide the select column
.ag-primary-cols-list-panel {
  // This is really working off the fact that the first div is the selector column
  .ag-column-tool-panel-column:first-of-type {
    display: none;
  }
}


.ag-column-tool-panel-column {

  .ag-column-drag {
    display: none;
  }

  .ag-column-tool-panel-column-label {
    margin-left: 8px;
  }
}

.tp-teachers {
  background: #b0e0ef;
}


// Beta

body:after{
  content: "beta";
  position: fixed;
  width: 80px;
  height: 25px;
  background: #EE8E4A;
  bottom: 7px;
  left: -20px;
  text-align: center;
  font-size: 13px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 27px;
  -ms-transform:rotate(-45deg);
  -webkit-transform:rotate(-45deg);
  transform:rotate(45deg);
  z-index: 1000;
}



// Intro JS
//::ng-deep {

  .introjs-tooltip.wide {
    max-width: 500px;
    width: 500px;

    .introjs-tooltiptext {
      text-align: center;
      padding: 20px;
    }
  }

  .tour {
    opacity: 0.8;
    background: #f7f7ed;
  }
//}


.error-text {
  color: #c22e38;
  font-style: italic;
}

.center-text {
  text-align: center;
  white-space: pre-wrap;
}

//steppers
.mat-mdc-dialog-content .mat-vertical-stepper-header {
  pointer-events: none !important;
}

.success-text {
  color: green;
}

.progress-spinner-text {
  font-style: italic;
}


//
//.loading:after {
//  overflow: hidden;
//  display: inline-block;
//  vertical-align: bottom;
//  -webkit-animation: ellipsis steps(4,end) 900ms infinite;
//  animation: ellipsis steps(4,end) 900ms infinite;
//  content: "\2026"; /* ascii code for the ellipsis character */
//  width: 0;
//}
//
//@keyframes ellipsis {
//  to {
//    width: 1.25em;
//  }
//}
//
//@-webkit-keyframes ellipsis {
//  to {
//    width: 1.25em;
//  }
//}
