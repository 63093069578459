.checkbox-section {

  p {
    margin: 6px;
  }

  span {
    font-size: 0.9em;
    font-style: italic;
  }

}