// Panels
@import './colors';

cdw-ae-export-wizard {
  h2.title {
    font-size: 18px;
    font-weight: 500;
  }
}

.ait-little-sis-panel {

  &.full {
    position: fixed !important;;
    right: 0 !important;;
    top: 0 !important;;
    bottom: 0 !important;;
    width: 75% !important;
    height: 100% !important;
    /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
    mat-dialog-container {
      overflow-x: hidden; // Hide scrollbar during any animation transition
    }

    .mat-mdc-dialog-container {

      .mat-mdc-dialog-content {
        min-height: calc(100% - 120px);
        max-height: calc(100% - 120px);
        padding: 8px 12px 0 12px;
      }
    }

  }

  &.full-no-top-padding{
    position: fixed !important;;
    right: 0 !important;;
    top: 0 !important;;
    bottom: 0 !important;;
    width: 75% !important;
    height: 100% !important;
    /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
    mat-dialog-container {
      overflow-x: hidden; // Hide scrollbar during any animation transition
    }

    .mat-mdc-dialog-container {

      .mat-mdc-dialog-content {

        padding: 0 12px 0 12px;
      }
    }

  }

  &.full-width {
    position: fixed !important;;
    right: 0 !important;;
    top: 0 !important;;
    bottom: 0 !important;;
    width: 75% !important;
    // height: 100% !important;
    /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
    mat-dialog-container {
      overflow-x: hidden; // Hide scrollbar during any animation transition
    }

    .mat-mdc-dialog-container {

      .mat-mdc-dialog-content {

        padding: 8px 12px 0 12px;
      }
    }

  }

  &.modal {
    //position: fixed !important;;
    //right: 0 !important;;
    //top: 0 !important;;
    //bottom: 0 !important;;
    //width: 75% !important;
    //height: 100% !important;
    /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
    mat-dialog-container {
      overflow-x: hidden; // Hide scrollbar during any animation transition
    }

    .mat-mdc-dialog-container {

      .mat-mdc-dialog-content {
        padding: 8px 12px 0 12px;
        height: calc(100% - 54px - 60px) !important;
        &:not(.exclude-max-height) {
          max-height: calc(100% - 54px - 60px) !important;
        }
      }
    }

  }

  .mat-mdc-dialog-actions {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  .mat-dialog-header {

    top: 0;
    background: #3270a9;
    color: #EFEFEF;
    width: 100%;
    padding: 12px;
    display: flex;
    flex-wrap: wrap;

    h2 {
      font-size:18px;
      line-height: 30px;
      margin: 0;
    }

    .mat-mdc-dialog-title {
      margin: 0;
      display: block;
    }

  }

  .mat-mdc-dialog-container {
    border-radius: 5px;
    padding: 0;

    .mat-mdc-dialog-content {
      margin: 0;

      &.flush {
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-body-content {
          padding: 0 !important;
        }
      }

    }

    .flush {
      padding: 0 !important;
    }

    .mat-mdc-dialog-actions {
      background: #d0e5f3;
      width: 100%;
      z-index: 100;
      height: 60px;
      padding-right: 15px;

      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;

      button {
        margin: 0 0 0 10px;
      }
    }

  }
}

.ait-tight-panel {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {
    margin: 0;
    padding: 4px 12px;
  }
}

.max-height {
  height: calc(100% - 50px) !important;
}


.grid-avatar {
  width: 28px;
  height: 28px;
  margin-right: 8px;
  border-radius: 50%;
}

.bulk-action-grid-header {
  background-color: #d8e8f9;
  color: #000000;
  line-height: 1em
}

.user-profile {
  //padding: 4px;
  //background: rgba(231, 236, 245, 0.3);
  //border-radius: 4px;
  color: #525151;
  overflow: hidden;
  padding: 4px 8px;
  background: rgba(234, 228, 228, 0.4);
  border-radius: 4px;
}

body.theme-default {

  .mat-badge-cool .mat-badge-content {
    color: white;
    background: #6cbdf4;
  }

}


.error-snackBar {
  background: red;

  a {
    color: #5f171c;
  }
}


.ait-message {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {
    background: #f8fcf8 !important;
    padding: 0 0 24px;

    .dialog-content {
      padding: 24px;
    }

    mat-icon {
      color: orangered;
    }
  }

  .actions {
    background: #c7e5e5;
  }
}


.ait-alert {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {

    background: #f8fcf8 !important;
    padding: 0 0 12px;

    .dialog-content {
      padding: 12px;

      mat-icon {
        color: orangered;
      }

    }

  }
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  .mat-dialog-header {
    top: 0;
    background: #3270a9;
    color: #EFEFEF;
    width: 100%;
    padding: 12px;
    display: flex;
    flex-wrap: wrap;

    h2 {
      margin: 0;
    }
  }

  .mat-mdc-dialog-actions {
    margin-bottom: -12px;
    background: #c7e5e5;
  }


  &.error {
    /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
    .mat-dialog-header {
      background: #e40909;
      color: #EFEFEF;
    }

    .mat-mdc-dialog-actions {
      background: #ffefef;
    }
  }
}


// Renewal / Licensing modal
.renew-modal {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {
    padding: 24px 24px 12px 24px;
    line-height: 1.5;

    p {
      margin-top: 0;
    }

    span.mb8 {
      margin-bottom: 8px;
    }

    a {
      color: $clr-link-blue;
      font-weight: 400;
      text-decoration: underline;
      outline: none;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
    mat-dialog-content {
      padding-bottom: 48px !important;

      .centered {
        align-content: center;
      }
    }
  }
}

.limited-actions-warning-bar {
  background-color: $alertRed;
  color: #FFF;
  font-weight: 500;
  padding: 12px;

  span {
    line-height: 1.4em;
  }
}
