.mse-state-hover {

  &:hover {
    cursor: pointer;
  }

}

.fabButton {
  position: fixed !important;
  bottom: 80px;
  right: 30px;
  z-index: 10;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
}

.on-hover { visibility: hidden; }

:hover > .on-hover {
  visibility: visible;
}